@use 'base/variables' as v;
@use 'base/mixins' as m;

.nosotros {
    background-color: v.$verde;
    padding: 5rem 0;

    h3, p {
        text-align: center;
        color: v.$negro;
    }
    .nosotros_p {
        
        margin: 5rem 0;
        color: v.$blanco;
        font-size: 3rem;
    }
}

.nosotros-contenedor {
    @include m.tablet {
        @include m.grid(2, 2rem);
    }

    p {
        color: v.$blanco;
    }
}

.redes {
    padding-top: 2rem;
    .nombre-redes {
        &:after {
            content: '';
            display: block;
            width: 5rem;
            height: 5rem;
            background-size: 5rem;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 2rem auto 0 auto;
        }
    }
}

.qr {
    padding-top: 2rem;
    .nombre-qr {
        
        content: '';
        display: block;
        width: 30rem;
        height: 30rem;
        background-size: 30rem;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 2rem auto 0 auto;
        background-image: url(../img/qr.png);
        
    }
}

.facebook .nombre-redes:after {
    background-image: url(../img/facebook-icon.png);
}

.instagram .nombre-redes:after {
    background-image: url(../img/instagram-icon.png);
}

.whatsapp .nombre-redes:after {
    background-image: url(../img/whatsapp-icon.png);
}

.gmail .nombre-redes:after {
    background-image: url(../img/icono-gmail.png);
}

.qr .nombre-qr-imagen {
    background-image: url(../img/qr.png);
}


.bg-nosotros {
    background-color: v.$verde;
   
}

.nosotros-grid{
    @include m.desktop {
        @include m.grid(2, .1rem);
    }
}


.nosotros-wpp{
    padding-top: 2rem;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
}

.nosotros-ig{
    padding-top: 2rem;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
}

.nosotros-fb{
    padding-top: 2rem;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
}

.nosotros-qr{
    padding-top: 2rem;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
}

.nosotros-gmail{
    padding-top: 2rem;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
}

.nosotros-qr-texto{
    padding-top: 10rem;
    text-align: right;
    font-size: 3rem;
}


.qr-contenedor {

    p {
        color: v.$blanco;
    }
}