@use 'variables' as v;

@mixin telefono {
    @media (min-width: v.$telefono) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: v.$tablet) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: v.$desktop) {
        @content;
    }
}
@mixin desktopXL {
    @media (min-width: v.$desktopXL) {
        @content;
    }
}
@mixin desktopXXL {
    @media (min-width: v.$desktopXXL) {
        @content;
    }
}

@mixin grid($columnas, $espaciado) {
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $espaciado;
}

@mixin resetear-lista{
    margin: 0;
    padding:0;
    list-style: none;
}