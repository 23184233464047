@use 'base/variables' as v;
@use 'base/mixins' as m;

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: green;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whatsapp-button:hover span,
  .whatsapp-button:focus span {
    display: block;
    width: 200px;
    height: 60px;
    line-height: 60px;
    padding-left: 10px;
    position: fixed;
    bottom: 20px;
    right: 90px;
    background-color: green;
    color: white;
    font-size: 14px;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: all 20.0s ease; /* aumentamos el tiempo de transición */
    white-space: nowrap; /* evita que el texto se desborde al cambiar el ancho */
    overflow: hidden; /* evita que el texto se desborde al cambiar el ancho */
  }
  
  .whatsapp-button span {
    text-align: center;
    display: none;
    border-radius: 0 50px 50px 0; /* agregamos bordes redondeados al contenedor */
  }
  
  .whatsapp-button:focus {
    outline: none;
  }
  
  .whatsapp-icon {
    display: block;
    width: 30px;
    height: 30px;
    background-image: url(../img/whatsapp.webp);
    background-size: cover;
  }
  
  .whatsapp-button:focus .whatsapp-icon {
    margin-right: 10px;
  }
  
  .whatsapp-button:focus:hover span {
    right: 80px;
  }