@use 'base/variables' as v;
@use 'base/mixins' as m;

.lineup {

    
    @include m.tablet {
        padding: 5rem 3rem;
    }
    @include m.desktop {
        padding: 5rem 15rem;
    }
    @include m.desktopXL {
        padding: 5rem 25rem;
    }
    @include m.desktopXXL {
        padding: 5rem 50rem;
    }
   
    
    background-color: v.$rosa;

    h3, p {
        text-align: center;
        color: v.$negro;
    }
    .dia {
        text-transform: uppercase;
        margin: 5rem 0;
    }
}

.escenarios-contenedor {
   padding: 1rem;
    @include m.tablet {
        @include m.grid(1, 0rem);
    }
    p {
        color: v.$blanco;
    }
}

.escenarios-contenedor-proximos {
    @include m.telefono {
        @include m.grid(1, 1rem);
    }
    @include m.tablet {
        @include m.grid(2, 1rem);
    }
    @include m.desktop {
        @include m.grid(2, 3rem);
    }
    padding: 1rem;
}

.animacion {
    position:relative;
    margin: 0;
    width: 70%;
    margin-top: .5rem;
    margin-bottom: 1rem;
    @include m.telefono{
        
        animation-name: animacion1;
        animation-duration: 2s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
    }
   
   
}


@keyframes animacion1 {
    from {

        left:-10%;
        background-color:transparent;
        color: transparent;
      }
      to {
        left:14%; 
        color: #ffffff;
        border-radius: .5rem;
    }
  
}


.escenario {
    padding-top: 2rem;
    padding-bottom: .1rem;
    .nombre-escenario {
        &:after {
            content: '';
            display: block;
            width: 20rem;
            height: 10rem;
            background-size: 15rem;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 2rem auto 0 auto;
        }
    }
}
.edm .nombre-escenario:after {
    background-image: url(../img/logo1.png);
    border-radius: 50%;
    width: 15rem;
    height: 15rem;
}
.rock .nombre-escenario:after {
    background-image: url(../img/logo1.png);
    border-radius: 50%;
    width: 15rem;
    height: 15rem;
    
}





.calendario {
    @include m.resetear-lista;
    li {
        padding: 1rem;
        font-weight: 700;
        color: v.$blanco;
        align-items: center;
        span {
            font-weight: 400;
        }
    }
    
}

.bg-amarillo {
    background-color: v.$amarillo;
    li:nth-child(odd) {
        background-color: darken(v.$amarillo, 15%);
    }
}
.bg-verde {
    background-color: v.$verde;
    li:nth-child(odd) {
        background-color: darken(v.$verde, 10%);
    }
}


.mapas {
    border:0;
    width: 100%;
    height: 260px;
    @include m.telefono{
        height: 280px;

    }
    @include m.tablet{
        height: 317px;

    }
    @include m.desktop{
        height: 340px;

    }
   
}


