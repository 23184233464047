@use 'base/variables' as v;
@use 'base/mixins' as m;

.contacto {
    background-color: v.$verde;
    padding: 5rem 0;

    h3, p {
        text-align: center;
        color: v.$negro;
    }
    .contacto_P {
        margin: 5rem 0;
        color: v.$blanco;
        font-size: 3rem;
        
    }
}

.contacto-contenedor {
   
    @include m.tablet {
        @include m.grid(2, .1rem);
    }

    p {
        color: v.$blanco;
    }
}