@use 'base/variables' as v;
@use 'base/mixins' as m;

.galeria {
    padding: 5rem 0;
    h3 {
        text-align: center;
    }
}

.galeria-imagenes {
    @include m.resetear-lista;
    @include m.grid(2, 2rem);
    @include m.telefono {
        @include m.grid(3, 2rem);
    }
    @include m.tablet {
        @include m.grid(4, 2rem);
    }
    @include m.desktop {
        @include m.grid(4, 2rem);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    background-color: rgb(0 0 0 / .5);

    picture {
        display: block;
        max-width: 80rem;
    }
}

.btn-cerrar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: v.$blanco;
    color: v.$negro;
    display: grid;
    place-items: center;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
}