// Tipografia
$fuente_principal: 'Montserrat', sans-serif;

// Colores
$verde:#a88653;
$rosa: #82c990;
$amarillo: #39923d;
$morado: #752F97;
$negro: #000000;
$blanco: #FFFFFF;
$gris: #d7d6d6;

// Tamaños de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;
$desktopXXL: 2400px;