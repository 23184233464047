@use 'base/variables' as v;
@use 'base/mixins' as m;

.video {
    position: relative;
    max-height: 70rem;
    min-height: 50rem;
    overflow: hidden;

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    video {
        width: auto;
        display: block;
        @include m.desktop {
            width: 100%;
        }
    }
}


.contenido-video {
    z-index: 10 ;
    h2 {
        @include m.tablet {
            font-size: 7rem;
        }
        color: v.$blanco;
        font-size: 4rem;
        margin: 0;
    }
    p {
        @include m.tablet {
            font-size: 2.5rem;
        }
        background-color: v.$rosa;
        display: inline-block;
        padding: 1rem 2rem;
        color: v.$negro;
        font-weight: 700;
        font-size: 1rem;
        margin: 0;
    }
   
}